import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
	NgcCookieConsentService,
	NgcNoCookieLawEvent,
	NgcInitializeEvent,
	NgcStatusChangeEvent,
} from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';

@Component({
	// tslint:disable-next-line
	selector: 'body',
	template:
		'<ngx-loading-bar [color]="\'green\'" [includeSpinner]="false"></ngx-loading-bar>' +
		'<router-outlet></router-outlet><p-toast [baseZIndex]="10000"></p-toast>',
})
export class AppComponent implements OnInit, OnDestroy {
	//keep refs to subscriptions to be able to unsubscribe later
	private popupOpenSubscription: Subscription;
	private popupCloseSubscription: Subscription;
	private initializeSubscription: Subscription;
	private statusChangeSubscription: Subscription;
	private revokeChoiceSubscription: Subscription;
	private noCookieLawSubscription: Subscription;

	constructor(
		private router: Router,
		private ccService: NgcCookieConsentService
	) {}

	handleClickSound() {
		let x = <HTMLVideoElement>document.getElementById('myAudio');
		x.play();
	}

	ngOnInit() {
		this.router.events.subscribe(evt => {
			if (!(evt instanceof NavigationEnd)) {
				return;
			}
			window.scrollTo(0, 0);
		});

		// subscribe to cookieconsent observables to react to main events
		this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
			// you can use this.ccService.getConfig() to do stuff...
		});

		this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
			// you can use this.ccService.getConfig() to do stuff...
		});

		this.initializeSubscription = this.ccService.initialize$.subscribe(
			(event: NgcInitializeEvent) => {
				// you can use this.ccService.getConfig() to do stuff...
			}
		);

		this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
			(event: NgcStatusChangeEvent) => {
				// you can use this.ccService.getConfig() to do stuff...
			}
		);

		this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
			() => {
				// you can use this.ccService.getConfig() to do stuff...
			}
		);

		this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
			(event: NgcNoCookieLawEvent) => {
				// you can use this.ccService.getConfig() to do stuff...
			}
		);
	}

	ngOnDestroy() {
		// unsubscribe to cookieconsent observables to prevent memory leaks
		this.popupOpenSubscription.unsubscribe();
		this.popupCloseSubscription.unsubscribe();
		this.initializeSubscription.unsubscribe();
		this.statusChangeSubscription.unsubscribe();
		this.revokeChoiceSubscription.unsubscribe();
		this.noCookieLawSubscription.unsubscribe();
	}
}
