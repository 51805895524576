import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
	PerfectScrollbarConfigInterface,
	PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { AppComponent } from './app.component';
// Import containers
import { DefaultLayoutComponent } from './containers';
import {
	AppAsideModule,
	AppBreadcrumbModule,
	AppFooterModule,
	AppHeaderModule,
	AppSidebarModule,
} from '@coreui/angular';
// Import routing module
import { AppRouting } from './app.routing';
// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ChartsModule } from 'ng2-charts';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MyCommonModule } from '../../../../src/app/common/common.module';
import { LoginComponent } from './admin/login/login.component';
import { RegisterComponent } from './admin/register/register.component';
import { ResetComponent } from './admin/reset/reset.component';
import { DataService } from './service/data.service';
import { TokenInterceptor } from './service/token.interceptor';
import { AutoLogoutService } from './service/autologout.service';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import {
	NgcCookieConsentModule,
	NgcCookieConsentConfig,
} from 'ngx-cookieconsent';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true,
};

const APP_CONTAINERS = [DefaultLayoutComponent];

const cookieConfig: NgcCookieConsentConfig = {
	cookie: {
		domain: window.location.hostname, // or 'your.domain.com'
		expiryDays: 1,
	},
	palette: {
		popup: {
			background: '#000',
		},
		button: {
			background: '#f1d600',
		},
	},
	revokable: false,
	position: 'bottom',
	theme: 'block',
	type: 'info',
	layout: 'my-custom-layout',
	layouts: {
		'my-custom-layout':
			'<span style="display: flex; justify-content: space-between; align-items: center;">{{messagelink}}{{compliance}}</span>',
	},
	elements: {
		messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a>, 
      <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a> and our 
      <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" href="{{tosHref}}" target="_blank">{{tosLink}}</a>
    </span>
    `,
		//	dismiss: '<a aria-label="dismiss cookie message" tabindex="10" class="cc-btn cc-dismiss"></a>',
		allow:
			'<a aria-label="allow cookies" tabindex="0" class="cc-btn cc-allow">Allow Cookie</a>',
		deny: '<a aria-label="deny cookies" tabindex="0" class="cc-btn cc-deny">Decline</a>',
	},
	content: {
		message:
			'By using our site, you acknowledge that you have read and understand our ',

		cookiePolicyLink: 'Cookie Policy',
		cookiePolicyHref: '/#',

		privacyPolicyLink: 'Privacy Policy',
		privacyPolicyHref: '/#',

		tosLink: 'Terms of Service',
		tosHref: '/#',
	},
};

@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRouting,
		AppAsideModule,
		AppBreadcrumbModule.forRoot(),
		PopoverModule.forRoot(),
		AccordionModule.forRoot(),
		NgcCookieConsentModule.forRoot(cookieConfig),
		AppFooterModule,
		AppHeaderModule,
		AppSidebarModule,
		PerfectScrollbarModule,
		BsDropdownModule.forRoot(),
		TabsModule.forRoot(),
		TimepickerModule.forRoot(),
		ChartsModule,
		AngularFireModule.initializeApp(environment.firebase),
		LoadingBarHttpClientModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		MatNativeDateModule,
		ToastModule,
		MatInputModule,
		MatSelectModule,
		MatDatepickerModule,
		ConfirmDialogModule,
		MyCommonModule,
	],
	declarations: [
		AppComponent,
		LoginComponent,
		ResetComponent,
		RegisterComponent,
		...APP_CONTAINERS,
	],
	providers: [
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
		{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
		DataService,
		MessageService,
		AutoLogoutService,
		ConfirmationService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
