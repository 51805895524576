<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-8 mx-auto">
          <div class="text-center">
            <img src="assets/img/tritek-logo.png" class="img-fluid" style="height: 60px;">
          </div>
          <h6 class="text-center">Welcome to Tritek Com Hub</h6>
          <div class="card px-4 py-4">
            <div *ngIf="!otpSent" class="animated fadeIn card-body">
              <form (ngSubmit)="onSubmit()" [formGroup]="verifyForm">
                <h1>Reset Password</h1>
                <p class="text-muted">
                  Enter your registered email below to get Password Reset OTP in your email
                </p>

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-envelope-open-o"></i></span>
                  </div>
                  <input [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="form-control"
                         formControlName="email" placeholder="email address" required type="text"/>
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div>Email is required</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <button [disabled]="loading" class="btn btn-primary" type="submit">Request OTP</button>
                    <img *ngIf="loading" class="pl-2"
                         src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                  </div>
                  <div class="col-6 text-right">
                    <a href="#" class="btn btn-link px-0" type="button">or Login Here</a>
                  </div>
                </div>
              </form>
            </div>
            <div *ngIf="otpSent" class="animated fadeIn card-body">
              <form (ngSubmit)="resetPassword()" [formGroup]="resetForm">
                <h1>Change Password</h1>
                <p class="text-muted">
                  Enter the OTP sent to your mail and your desired password
                </p>

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-lock"></i></span>
                  </div>
                  <input [ngClass]="{ 'is-invalid': submitted && r.otp.errors }" class="form-control"
                         formControlName="otp" placeholder="OTP" required type="text"/>
                  <div *ngIf="submitted && r.otp.errors" class="invalid-feedback">
                    <div>OTP is required</div>
                  </div>
                </div>

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-lock"></i></span>
                  </div>
                  <input [ngClass]="{ 'is-invalid': submitted && f.password.errors }" autocomplete="password"
                         class="form-control" formControlName="password" placeholder="Password" required
                         [type]="fieldTypePassword ? 'password' : 'text'"/>
                  <div class="input-group-append">
                    <span *ngIf="fieldTypePassword" (click)="viewPassword()" class="input-group-text"><i class="fa fa-eye-slash"></i></span>
                    <span *ngIf="!fieldTypePassword" (click)="viewPassword()" class="input-group-text"><i class="fa fa-eye"></i></span>
                  </div>
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div>Password must be minimum of 8 characters with both lower/uppercase letters and numbers</div>
                  </div>
                </div>

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-lock"></i></span>
                  </div>
                  <input [ngClass]="{ 'is-invalid': submitted && f.password_confirmation.errors }" class="form-control"
                         formControlName="password_confirmation" placeholder="Confirm Password" required [type]="fieldTypePassword ? 'password' : 'text'"/>
                  <div *ngIf="submitted && f.password_confirmation.errors" class="invalid-feedback">
                    <div>Password must match</div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <button [disabled]="loading" class="btn btn-primary" type="submit">Reset Password</button>
                    <img *ngIf="loading" class="pl-2"
                         src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                  </div>
                </div>
              </form>
            </div>

          </div>

          <p class="text-center mt-3">Copyright &copy;{{ date }} <a href="#"> Tritek Consulting Limited </a></p>
        </div>
      </div>
    </div>
  </main>
</div>
