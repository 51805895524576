<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-7 col-sm-10 col-lg-5 mx-auto">
          <div class="text-center">
            <img src="assets/img/tritek-logo.png" class="img-fluid" style="height: 60px;">
          </div>
          <h6 class="text-center">Welcome to Tritek ComHub</h6>
          <div class="card p-2">
            <div class="card-body">

              <form [formGroup]="loginForm">
                <h1>Login</h1>
                <p class="text-muted">Sign In to your account</p>

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-user"></i></span>
                  </div>
                  <input [ngClass]="{ 'is-invalid': submitted && f.username.errors }" autocomplete="username"
                         class="form-control" formControlName="username" placeholder="username or email" required
                         type="text"/>
                  <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required">Username is required</div>
                  </div>
                </div>

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-lock"></i></span>
                  </div>
                  <input [ngClass]="{ 'is-invalid': submitted && f.password.errors }" autocomplete="password"
                         class="form-control" formControlName="password" placeholder="Password" required
                         [type]="fieldTypePassword ? 'password' : 'text'"/>
                  <div class="input-group-append">
                    <span *ngIf="fieldTypePassword" (click)="viewPassword()" class="input-group-text"><i class="fa fa-eye-slash"></i></span>
                    <span *ngIf="!fieldTypePassword" (click)="viewPassword()" class="input-group-text"><i class="fa fa-eye"></i></span>
                  </div>
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div>Password is required</div>
                  </div>
                </div>

                <!--<div class="input-group">-->
                <!--<button subscription="submit" [disabled]="loading" class="btn btn-primary">Login</button>-->
                <!--<img *ngIf="loading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />-->
                <!--</div>-->

                <div class="row">
                  <div class="col-6">
                    <button [disabled]="loading" (click)="onSubmit()" class="btn btn-primary" type="submit">Login</button>
                    <img *ngIf="loading" class="pl-2"
                         src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                  </div>
                  <div class="col-6 text-right">
                    <a href="#/auth_reset" class="btn btn-link px-0" type="button">Forgot password?</a>
                  </div>
                </div>
                <div *ngIf="error" class="alert alert-danger">{{error}}</div>
              </form>

            </div>
          </div>
          <p class="text-center mt-3">Copyright &copy;{{ date }} <a href="#"> Tritek Consulting Limited </a></p>
        </div>
      </div>
    </div>
  </main>
</div>
