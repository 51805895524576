<app-header
	[asideMenuToggler]="false"
	[fixed]="true"
	[navbarBrandFull]="{
		src: 'assets/logo.png',
		width: 30,
		height: 26,
		alt: 'Tritek Logo'
	}"
	[navbarBrandMinimized]="{
		src: 'assets/logo.png',
		width: 30,
		height: 26,
		alt: 'Tritek Logo'
	}"
	[navbarBrandRouterLink]="['/channels']"
	[mobileAsideMenuToggler]="false"
	[sidebarToggler]="'lg'"
>
	<h3 class="text-center">Tritek ComHub</h3>
	<ul class="nav navbar-nav ml-auto">
		<!--    <li dropdown placement="bottom right" class="nav-item dropdown d-md-down-none">-->
		<!--      <span data-toggle="dropdown" role="button" aria-haspopup="true" class="nav-link" dropdownToggle>-->
		<!--        <i class="icon-envelope-letter"></i>-->
		<!--        <span class="badge badge-pill badge-info">7</span>-->
		<!--      </span>-->
		<!--      <div aria-labelledby="simple-dropdown" class="dropdown-menu dropdown-menu-right dropdown-menu-lg show" *dropdownMenu>-->
		<!--        <div class="dropdown-header text-center">-->
		<!--          <strong>You have 4 messages</strong>-->
		<!--        </div>-->
		<!--        <a href="#" class="dropdown-item">-->
		<!--          <div class="message">-->
		<!--            <div class="py-3 mr-3 float-left">-->
		<!--              <div class="avatar">-->
		<!--                <img src="assets/img/avatars/7.jpg" alt="admin@bootstrapmaster.com" class="img-avatar">-->
		<!--                <span class="avatar-status badge-success"></span>-->
		<!--              </div>-->
		<!--            </div>-->
		<!--            <div>-->
		<!--              <small class="text-muted">John Doe</small>-->
		<!--              <small class="text-muted float-right mt-1">Just now</small>-->
		<!--            </div>-->
		<!--            <div class="text-truncate font-weight-bold">-->
		<!--              <span class="fa fa-exclamation text-danger"></span>-->
		<!--              Important message-->
		<!--            </div>-->
		<!--            <div class="small text-muted text-truncate">-->
		<!--              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...-->
		<!--            </div>-->
		<!--          </div>-->
		<!--        </a>-->
		<!--        <a href="#" class="dropdown-item">-->
		<!--          <div class="message">-->
		<!--            <div class="py-3 mr-3 float-left">-->
		<!--              <div class="avatar">-->
		<!--                <img src="assets/img/avatars/6.jpg" alt="admin@bootstrapmaster.com" class="img-avatar">-->
		<!--                <span class="avatar-status badge-warning"></span>-->
		<!--              </div>-->
		<!--            </div>-->
		<!--            <div>-->
		<!--              <small class="text-muted">John Doe</small>-->
		<!--              <small class="text-muted float-right mt-1">5 minutes ago</small>-->
		<!--            </div>-->
		<!--            <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>-->
		<!--            <div class="small text-muted text-truncate">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...</div>-->
		<!--          </div>-->
		<!--        </a>-->
		<!--        <a href="#" class="dropdown-item">-->
		<!--          <div class="message">-->
		<!--            <div class="py-3 mr-3 float-left">-->
		<!--              <div class="avatar"><img src="assets/img/avatars/5.jpg" alt="admin@bootstrapmaster.com" class="img-avatar">-->
		<!--                <span class="avatar-status badge-danger"></span>-->
		<!--              </div>-->
		<!--            </div>-->
		<!--            <div>-->
		<!--              <small class="text-muted">John Doe</small>-->
		<!--              <small class="text-muted float-right mt-1">1:52 PM</small>-->
		<!--            </div>-->
		<!--            <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>-->
		<!--            <div class="small text-muted text-truncate">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...</div>-->
		<!--          </div>-->
		<!--        </a>-->
		<!--        <a href="#" class="dropdown-item">-->
		<!--          <div class="message">-->
		<!--            <div class="py-3 mr-3 float-left">-->
		<!--              <div class="avatar">-->
		<!--                <img src="assets/img/avatars/4.jpg" alt="admin@bootstrapmaster.com" class="img-avatar">-->
		<!--                <span class="avatar-status badge-info"></span>-->
		<!--              </div>-->
		<!--            </div>-->
		<!--            <div>-->
		<!--              <small class="text-muted">John Doe</small>-->
		<!--              <small class="text-muted float-right mt-1">4:03 PM</small>-->
		<!--            </div>-->
		<!--            <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>-->
		<!--            <div class="small text-muted text-truncate">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...</div>-->
		<!--          </div>-->
		<!--        </a>-->
		<!--        <a href="#" class="dropdown-item text-center">-->
		<!--          <strong>View all messages</strong>-->
		<!--        </a>-->
		<!--      </div>&lt;!&ndash;&ndash;&gt;-->
		<!--    </li>-->
		<li class="nav-item d-md-down-none">
			<a class="nav-link" href="#"></a>
		</li>
		<li class="nav-item dropdown" dropdown placement="bottom right">
			<a
				(click)="(false)"
				aria-expanded="false"
				aria-haspopup="true"
				class="nav-link"
				data-toggle="dropdown"
				dropdownToggle
				href="#"
				role="button"
			>
				<img
					*ngIf="!loginUser.passport && !loginUser.avatar"
					[src]="imageSrc['passport']"
					alt="{{ loginUser.username }} avatar"
					class="img-avatar"
				/>
				<img
					*ngIf="!loginUser.passport && loginUser.avatar"
					[src]="loginUser.avatar"
					alt="{{ loginUser.username }} avatar"
					class="img-avatar"
				/>
				<img
					*ngIf="loginUser.passport"
					alt="{{ loginUser.username }} avatar"
					class="img-avatar"
					src="{{ fsPath }}"
				/>
			</a>
			<div
				*dropdownMenu
				aria-labelledby="simple-dropdown"
				class="dropdown-menu dropdown-menu-right"
			>
				<a style="cursor: pointer" href="#/profile" class="dropdown-item"
					><i class="fa fa-picture-o"></i>View Profile</a
				>
				<a
					style="cursor: pointer"
					class="dropdown-item text-warning"
					href="#/profile-options"
					><i class="fa fa-edit"></i>Edit Profile</a
				>
				<a (click)="logout()" style="cursor: pointer" class="dropdown-item"
					><i class="fa fa-lock"></i> Logout</a
				>
			</div>
		</li>
	</ul>
</app-header>
<div class="app-body">
	<app-sidebar
		#appSidebar
		[display]="student"
		[fixed]="true"
		[minimized]="false"
	>
		<app-sidebar-nav
			[disabled]="appSidebar.minimized"
			[navItems]="navItems"
			[perfectScrollbar]
		></app-sidebar-nav>
		<app-sidebar-minimizer></app-sidebar-minimizer>
	</app-sidebar>

	<!-- Main content -->
	<main class="main">
		<div class="container-fluid pt-3">
			<router-outlet></router-outlet>
		</div>
		<span class="fab-btn">
			<button
				type="button"
				class="btn btn-lg btn-dark rounded-circle"
				placement="top"
				tooltip="Help and Support"
				popoverTitle="Help and Support"
				[popover]="popTemplate"
				containerClass="c-popover"
			>
				<i class="fa fa-info" style="font-size: 25px"></i>
			</button>
		</span>
		<ng-template #popTemplate>
			<div class="d-flex flex-wrap">
				<a href="#/faq" class="inner-btn btn btn-outline-dark">
					<div class="d-block text-center">FAQ</div>
					<i class="fa fa-info inner-icon"></i>
				</a>
				<a href="#/tutorial" class="inner-btn btn btn-outline-dark">
					<div class="d-block text-center">User Tutorial</div>
					<i class="fa fa-film inner-icon"></i>
				</a>
				<a href="#/contact" class="inner-btn btn btn-outline-dark">
					<div class="d-block text-center">Contact Support</div>
					<i class="fa fa-envelope inner-icon"></i>
				</a>
			</div>
		</ng-template>
		<!-- /.container-fluid -->
	</main>
</div>

<app-footer [fixed]="true">
	<span
		><a href="https://tritekconsulting.co.uk">Tritek ComHub</a> &copy;
		{{ year }}.</span
	>
</app-footer>
<p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>
